<template>
  <div class="base">
    <div data-anima="top">
      <h1>{{ $t('settings.custom.txt1') }}</h1>
      <div class="line"></div>
      <Item
        :title="$t('settings.custom.txt2')"
        @remove="remove"
        :value="null"
        param="logo"
        :descricao="$t('settings.custom.txt3')"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer;"
            @click="showModalUploadLogo"
            v-if="!logo"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="logo"
              plain
            ></b-form-file>
            {{ logo }}
          </div>
          <div class="image" @click="showModalUploadLogo" v-else>
            <img :src="logo" alt="logo" />
          </div>
          <div>
            <span
              >{{$t('settings.custom.txt4')}}
              {{ logo ? $t('settings.custom.txt5') : "" }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt6')"
        :value="null"
        param="logomarca"
        @remove="remove"
        :descricao="$t('settings.custom.txt7')"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer, display: flex;"
            @click="showModalUploadLogomarca"
            v-if="!logomarca"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="logomarca"
              plain
            ></b-form-file>
          </div>
          <div class="image" @click="showModalUploadLogomarca" v-else>
            <img :src="logomarca" alt="logomarca" />
          </div>
          <div>
            <span
              >{{$t('settings.custom.txt8')}}
              {{
                logomarca ? $t('settings.custom.txt5') : ""
              }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt9')"
        :value="null"
        @remove="remove"
        param="login_background_image"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer;"
            @click="showModalUploadBackground"
            v-if="!login_background_image"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="login_background_image"
              plain
            ></b-form-file>
          </div>
          <div class="image" @click="showModalUploadBackground" v-else>
            <img :src="login_background_image" alt="login_background_image" />
          </div>
          <div>
            <span
              >{{$t('settings.custom.txt10')}}
              {{
                login_background_image
                  ? $t('settings.custom.txt5')
                  : ""
              }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt11')"
        :value="null"
        param="rating_home"
        :descricao="$t('settings.custom.txt12')"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="flex">
            <v-swatches
              v-model="main_color"
              :swatches="swatches"
              row-length="1"
              @input="changedColor"
              inline
            >
            </v-swatches>
            <div class="ml-5 flex">
              <div><span>{{ $t('settings.custom.txt13') }}</span></div>
              <InputColorPicker
                class="ml-3"
                @change="changedColor"
                v-model="main_color"
              />
            </div>
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt14') "
        :value="null"
        @update="update"
        param="termsOfUseActivated"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton @click="openModalConfigPainel" variant="link-info"
              >{{ $t('settings.custom.txt15') }}</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt16')"
        :value="hide_courses"
        @update="update"
        param="hide_courses"
        :descricao="$t('settings.custom.txt17')"
      >
        <template v-slot:icon>
          <categoryIcon />
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt20')"
        :value="home_carousel"
        @update="update"
        param="home_carousel"
        :descricao="$t('settings.custom.txt21')"
      >
        <template v-slot:icon>
          <gridIcon />
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.show_course')"
        :value="view_course"
        @update="updateView"
        param="view_course"
        :descricao="getText()"
        :html="true"
      >
        <template v-slot:icon>
          <listIcon />
        </template>
        <template v-slot:sub>
          <div v-if="view_course">
            <p class="cover_format">{{$t('settings.custom.cover_format')}}</p>
            <b-form-radio-group
              id="radio-group-4"
              class="mt-3 cover_options"
              v-model="home_vertical"
              :name="$t('settings.custom.nameRadioView')"
              @change="changeView"
            >
              <b-form-radio value="film_cover">{{$t('settings.custom.film_cover')}}</b-form-radio>
              <b-form-radio value="horizontal_cover">{{$t('settings.custom.horizontal_cover')}}</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.show_module')"
        :value="without_category"
        @update="updateView"
        param="without_category"
        :descricao="getTextModule()"
        :html="true"
      >
        <template v-slot:icon>
          <listIcon />
        </template>
        <template v-slot:sub>
          <div v-if="without_category">
            <p class="cover_format">{{$t('settings.custom.cover_format')}}</p>
            <b-form-radio-group
              id="radio-group-3"
              class="mt-3 cover_options"
              v-model="home_vertical"
              :name="$t('settings.custom.nameRadioModule')"
              @change="changeView"
            >
              <b-form-radio value="film_cover">{{$t('settings.custom.film_cover')}}</b-form-radio>
              <b-form-radio value="horizontal_cover">{{$t('settings.custom.small_vertical_cover')}}</b-form-radio>
              <b-form-radio value="small_vertical_cover">{{$t('settings.custom.horizontal_cover')}}</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt24')"
        :value="enable_category"
        @update="update"
        param="enable_category"
        :descricao="$t('settings.custom.txt25')"
      >
        <template v-slot:icon>
          <categoryButton />
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt33')"
        :value="learning_progression"
        @update="update"
        param="learning_progression"
        :descricao="$t('settings.custom.txt34')"
      >
        <template v-slot:icon>
          <learningProgression />
        </template>
      </Item>
      <Item
        :title="$t('settings.custom.txt26')"
        :value="theme_fixed_painel"
        @update="update"
        param="theme_fixed_painel"
        :descricao="$t('settings.custom.txt27')"
      >
        <template v-slot:icon>
          <themeIcon />
        </template>
        <template v-slot:sub>
          <div v-if="theme_fixed_painel">
            <b-form-radio-group
              id="radio-group-2"
              class="mt-3"
              v-model="theme_fixed_painel_color"
              :name="$t('settings.custom.txt28')"
              @change="changeValueThemeFixed"
            >
              <b-form-radio value="light">{{$t('settings.custom.txt29')}}</b-form-radio>
              <b-form-radio value="dark">{{$t('settings.custom.txt30')}}</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
    </div>
    <ModalConfigPainel />
    <ModalUpload />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import Item from "./Item.vue";

import categoryIcon from "./icons/category.vue";
import customIcon from "./icons/custom.vue";
import capaIcon from "./icons/capa.vue";
import gridIcon from "./icons/grid.vue";
import listIcon from "./icons/list.vue";
import themeIcon from "./icons/theme.vue";
import learningProgression from "./icons/learningProgression.vue";
import categoryButton from "./icons/categoryButton.vue";

import ModalConfigPainel from "@/components/ModalConfigPainel.vue";
import ModalUpload from "@/components/ModalUpload.vue";
import InputColorPicker from "vue-native-color-picker";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
  components: {
    Item,
    InputColorPicker,
    VSwatches,
    customIcon,
    ModalUpload,
    capaIcon,
    gridIcon,
    themeIcon,
    learningProgression,
    listIcon,
    categoryIcon,
    categoryButton,
    ModalConfigPainel,
  },
  data() {
    return {
      main_color: "#002363",
      swatches: [
        ["#002363"],
        ["#002363"],
        ["#FF0C37"],
        ["#FFCB37"],
        ["#2ED7EE"],
        ["#5D21D2"],
        ["#5A68DF"],
        ["#FF37D3"],
      ],
      without_category: false,
      view_course: false,
      home_carousel: false,
      home_vertical: '',
      hide_courses: false,
      enable_category: false,
      theme_fixed_painel: false,
      theme_fixed_painel_color: "dark",
      module_view: false,
      course_view: false,
      learning_progression: false,
      login_background_image: "",
      login_background_image_id: null,
      logomarca: "",
      logo: "",
    };
  },
  methods: {
    ...mapActions({
      actionDefineTheme: "config/actionDefineTheme",
      actionSaveSettings: "config/actionSaveSettings",
    }),
    getKeys(key) {
      var keys = [];
      if (key) {
        keys = [key];
      } else {
        keys = [
          "main_color",
          "without_category",
          "view_course",
          "enable_category",
          "home_carousel",
          "home_vertical",
          "hide_courses",
          "theme_fixed_painel",
          "theme_fixed_painel_color",
          "learning_progression",
          "login_background_image",
          "logomarca",
          "logo",
        ];
      }
      var result = "";
      for (let i = 0; i < keys.length; i++) {
        if (keys.length - 1 == i) {
          result += `keys[]=${keys[i]}`;
        } else {
          result += `keys[]=${keys[i]}&`;
        }
      }
      serviceMeta
        .search(result)
        .then((resp) => {
          if (resp.main_color !== undefined) {
            this.main_color = resp.main_color;
          }
          if (resp.login_background_image !== undefined) {
            if (resp.login_background_image) {
              this.login_background_image = resp.login_background_image.cdn_url;
            }
          }
          if (resp.logomarca !== undefined) {
            this.logomarca = resp.logomarca;
          }
          if (resp.logo !== undefined) {
            this.logo = resp.logo;
          }

          if (resp.without_category !== undefined) {
            if (
              resp.without_category === "off" ||
              resp.without_category === null
            ) {
              this.without_category = false;
              this.view_course = true;
            } else {
              this.without_category = true;
              this.view_course = false;
            }
          }

          if (resp.view_course !== undefined && resp.view_course !== null) {
            if (
              resp.view_course === "off"
            ) {
              this.view_course = false;
              this.without_category = true;
            } else {
              this.view_course = true;
              this.without_category = false;
            }
          }

          if (resp.enable_category !== undefined) {
            if (
              resp.enable_category === "off" ||
              resp.enable_category === null
            ) {
              this.enable_category = false;
            } else {
              this.enable_category = true;
            }
          }

          if(resp.home_vertical !== undefined){
            if(resp.home_vertical === false || resp.home_vertical === 'off'){
              this.home_vertical = 'horizontal_cover';
            }else if(resp.home_vertical === true || resp.home_vertical === 'on'){
              this.home_vertical = 'film_cover';
            }else{
              this.home_vertical = resp.home_vertical;
            }
          }

          if (resp.home_carousel !== undefined) {
            if (resp.home_carousel === "off" || resp.home_carousel === null) {
              this.home_carousel = false;
            } else {
              this.home_carousel = true;
            }
          }
          if (resp.hide_courses !== undefined) {
            if (resp.hide_courses === null || resp.hide_courses === "on") {
              this.hide_courses = true;
            } else {
              this.hide_courses = false;
            }
          }
          if (resp.theme_fixed_painel !== undefined) {
            if (resp.theme_fixed_painel === "off") {
              this.theme_fixed_painel = false;
            } else {
              this.theme_fixed_painel = true;
            }
          }
          if (resp.theme_fixed_painel_color !== undefined) {
            if (resp.theme_fixed_painel_color !== null) {
              this.theme_fixed_painel_color = resp.theme_fixed_painel_color;
            } else {
              this.changeValueThemeFixed("light");
            }
            this.$root.$emit("loadOff");
          }

          if(resp.learning_progression !== undefined){
            if (resp.learning_progression === "off") {
              this.learning_progression = false;
            } else {
              this.learning_progression = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mensageUpdate() {
      this.$bvToast.toast(this.$t('settings.custom.txt31'), {
        title: this.$t('settings.custom.txt32'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    update(item, showMessage = true) {
      let data = {
        id: item[1],
        value: item[0] ? "on" : "off",
      };
      this.actionSaveSettings(data).then(() => {
        if(showMessage){
          this.mensageUpdate();
        }
        this.getKeys(item[1]);
      });
    },
    updateView(item) {
      let itemChanged;
      this.update(item);

      const [hasItem, viewType] = item;

      if (viewType === 'view_course') {
        itemChanged = [!hasItem, "without_category"];
        this.changeView(hasItem ? 'film_cover' : 'horizontal_cover', false);
      } else {
        itemChanged = [!hasItem, "view_course"];
        
        if (!hasItem) {
          this.changeView('film_cover', false);
        } else {
          this.changeView('horizontal_cover', false);
        }
      }

      this.update(itemChanged, false);
    },
    changeValueThemeFixed(theme) {
      let data = {
        id: "theme_fixed_painel_color",
        value: theme,
      };

      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("theme_fixed_painel_color");
        this.actionDefineTheme(theme);
      });
    },
    changeView(home, showMessage = true) {
      let data = {
        id: "home_vertical",
        value: home,
      };
      this.home_vertical = home;
      serviceMeta.postID(data).then(() => {
        if(showMessage){
          this.mensageUpdate();
        }
        this.getKeys("home_vertical");
      });
    },
    changedColor() {
      var data = {
        id: "main_color",
        value: this.main_color,
      };

      serviceMeta.postID(data).then(() => {
        if (this.main_color !== null) {
          const doc = document.documentElement;
          doc.setAttribute("data-color", this.main_color);
          doc.style.setProperty("--maincolor", this.main_color);
          doc.style.setProperty(
            "--maincolorn",
            this.$func.convertColor(this.main_color, 210)
          );
          doc.style.setProperty("--maincolortrans", this.main_color + "0f");

          localStorage.setItem("mainColor", this.main_color);
        }
        this.mensageUpdate();
        this.getKeys("main_color");
        this.$root.$emit("changedmaincolor", this.color);
        this.$root.$emit("loadOff");
      });
    },
    updateLogoPainel() {
      var data = {
        id: "logo",
        value: this.logo,
      };
      serviceMeta.postID(data).then(() => {
        this.getKeys("logo");
        this.mensageUpdate();
        this.$root.$emit("changedlogo");
      });
    },
    updateLogomarcaPainel() {
      var data = {
        id: "logomarca",
        value: this.logomarca,
      };
      serviceMeta.postID(data).then(() => {
        this.getKeys("logomarca");
        this.mensageUpdate();
        this.$root.$emit("changedlogomarca");
      });
    },
    updateBackgroundLogin() {
      var data = {
        id: "login_background_image",
        value: this.login_background_image_id,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("login_background_image");
      });
    },
    remove(item) {
      var data = {
        id: item,
        value: null,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(item);
      });
    },
    openModalConfigPainel() {
      this.$root.$emit("openeditproject");
      this.$root.$emit("bv::show::modal", "modal-config-painel", "#btnShow");
    },
    showModalUploadLogo() {
      this.$root.$emit("logopainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadLogomarca() {
      this.$root.$emit("logomarcapainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadBackground() {
      this.$root.$emit("backgroundlogin");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    getText() {
      return `${this.$t("settings.custom.setup_course_part1")} <strong>${this.$t("settings.custom.setup_course")}</strong>${this.$t("settings.custom.setup_course_part2")}`;
    },
    getTextModule(){
      return `${this.$t("settings.custom.setup_module_part1")} <strong>${this.$t("settings.custom.setup_module")}</strong>${this.$t("settings.custom.setup_module_part2")}`;
 
    }
  },
  mounted() {
    this.getKeys();
    this.$root.$on("newlogopainel", (data) => {
      this.logo = data.url;
      this.updateLogoPainel();
    });
    this.$root.$on("newlogomarcapainel", (data) => {
      this.logomarca = data.url;
      this.updateLogomarcaPainel();
    });
    this.$root.$on("newbackgroundlogin", (data) => {
      this.login_background_image = data.url;
      this.login_background_image_id = data.id;
      this.updateBackgroundLogin();
    });
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  margin-bottom: 20px;
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #81858e;
    margin: 0;
  }
}

.vue-swatches__swatch {
  border-radius: 9px !important;
}

.inputSub {
  height: 40px;
  border: 1px solid #ededf0 !important;
  border-radius: 30px;
  font-weight: 400;
  font-size: 13px;
  color: #81858e;
  width: 40%;
  margin-top: 10px;
}

.base {
  width: calc(100% - 50px);
  padding-right: 50px;
  margin-bottom: 80px;
}

.text {
  font-weight: 600;
  font-size: 13px;
  color: var(--fontcolor2);
  margin-bottom: 5px;
}

.flex {
  display: flex;
  margin-top: 10px;
}

.icp__input {
  width: 42px !important;
  height: 42px !important;
  border-radius: 9px !important;
  background: #ffffff00 !important;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 25px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 25px;
}

.input-file {
  border: 1px solid #ededf0 !important;
  padding: 13px;
  border-radius: 30px;
  pointer-events: none;
}

.image {
  cursor: pointer;
  height: 100px;
  border: 1px solid #ededf0 !important;
  padding: 13px;
  border-radius: 30px;
  margin-top: 10px;
  img {
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 2px;
  }
}

.cover_format{
  color: var(--fontcolor);
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.8px;
}

.cover_options{
    display: flex;
    flex-direction: column;
}
</style>
